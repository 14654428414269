@import '@ctrl/ngx-emoji-mart/picker';
/* You can add global styles to this file, and also import other style files */
* {
  /* outline: red dashed 1px; */
  /* background-color: rgba(255, 0, 0, 0.1); */
}

a{
  color: #3fb2cd;
  text-decoration: none;
}
a:focus{
  outline: none;
}

.bg-patreon {
    background-color: #f96854 !important;
    border-color: #f96854 !important;
    color: #fff !important;
}

.bg-paypal {
    background-color: #253b80 !important;
    border-color: #253b80 !important;
    color: #fff !important;
}

.txt-paypal {
    color: #dfe6e9;
    --background-color: #222d65;
    --forground-color: #d7b016;
    text-shadow:
    0 0 7px var(--forground-color),
    0 0 10px var(--forground-color),
    0 0 21px var(--forground-color),
    0 0 42px ,
    0 0 82px var(--background-color),
    0 0 92px var(--background-color),
    0 0 102px var(--background-color),
    0 0 151px var(--background-color);
}

.bg-discord {
    background-color: #7289DA !important;
    border-color: #7289DA !important;
    color: #fff !important;
}

.fit {
  width: fit-content;
}

.premium_info {
    font-size: 0.875em;
    bottom: 0.25em;
    position: relative;
}

.rounded-top-left-05 {
    border-radius: 0rem !important;
    border-top-left-radius: 0.5rem !important;
}

.rounded-top-right-05 {
    border-radius: 0rem !important;
    border-top-right-radius: 0.5rem !important;
}

.rounded-top-left-025 {
    border-radius: 0rem !important;
    border-top-left-radius: 0.25rem !important;
}

.rounded-top-right-025 {
    border-radius: 0rem !important;
    border-top-right-radius: 0.25rem !important;
}

/* body.dark-mode {
  background-color: #545454;
  color: #dfe6e9;
}

body.light-mode {
  background-color: #ffffff;
  color: #2d3436;
} */

/* body.bd-title.dark-mode {
  color: #1c2021;
} */

.card-bg-dark {
  background-color: #343a40;

}

.title-bg-dark {
  background-color: #343a40;
  color: #d2d2d2;
}

.guild-bg-dark {
  background-color: #141414;
  color: #d2d2d2;
  margin: 0;
}

html, body { height: 100%; }

body {
  font-size: 16px;
  background: #292e34 !important;
  color: #4c4d4d !important;
  /* font-family: "Poppins", sans-serif; */
  font-weight: 300;
  max-height: 100%;
  overflow-x: hidden;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-image: url('/assets/images/body-bg.png');
  background-attachment: scroll;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
}

.hr-bg-dark {
  height: 1px;
  border: none;
  background-color: #141414;
  background-image: linear-gradient(to right, rgb(208, 255, 1), rgb(0, 145, 255));
  background-position: bottom;
  background-size: 100% 1px;
  background-repeat: no-repeat;
  padding-top: 1rem;
  margin-bottom: 1rem;
}

/*
NOTE: This removes the scrollbar from the guild-dashboard sidenav.
This only works on Chrome. Thats why there is also a logic in the guild-dashboard.component.ts
*/
.mat-drawer-inner-container::-webkit-scrollbar {
  display: none;
}

.mat-drawer-content {
  /* overflow: hidden; */
}

.card-content {
  background: #343a40;

  /* max-height: calc(100vh - 604px) !important; */
  min-height: 667px;

  border-radius: 10px;
  /* font-size: 2rem; */
  color: lightgray;
  padding-bottom: 16px;
  padding-left: 1rem;
  padding-right: 1rem;
  min-height: calc(100vh - 525px);

}

.overview-card {
  align-items: center;
  width: 100%;
  border-radius: 10px;
  background-color: rgb(59, 63, 67) !important;
  border: 1px solid #292d31;
}

.full-width-container {
  width: 100%;
}

.full-width-button {
  width: 100%;
}

/* Make links clickable even if user does not has edit permissions */
.mat-select-link-wrapper {
  pointer-events: auto;
}

/* Change color of links to increase visibility */
.mat-select-link {
  color: #52a3f4;
}
.mat-select-link:hover {
  color: #3084d7;
}

/* Selection text */
.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: #b4db06 !important;
}

/* Selection checkmark */
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b4db06 !important;
}

/* Selection label */
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #b4db06 !important;
}

/* Selection dropdown arrow */
.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: #b4db06 !important;
}

/* Selection bottom line */
.mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: #b4db06 !important;
}

/* Form input */
.form-control:focus {
  background-color: rgb(110, 110, 110) !important;
  box-shadow: none; /* Disable border */
}

/* Header Style */
.site-header .navbar {
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  transition: all 0.5s ease;
}
/* ./ Header Style */
/* Footer Style */
.site-footer{
  padding: 70px 0 20px;
}
.footer-logo a{
  font-size: 20px;
  color: #fff;
  text-decoration: none;
}
.social-links ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  padding: 0;
}
.social-links ul li a{
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  background-color: #3fb2cd;
  border: 1px solid #3fb2cd;
  color: #212529;
  padding: 5px;
  border-radius: 50%;
  transition: all 0.5s ease;
  text-decoration: none;
}
.social-links ul li a:hover{
  background-color: transparent;
  border: 1px solid #3fb2cd;
  color: #3fb2cd;
}
.sl-title {
  font-size: 18px;
}
.widget-title {
  display: block;
  position: relative;
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 10px;
  color: #fff;
}
.footer-menu li a {
  padding: 6px 0;
  color: rgb(177, 177, 177);
  text-decoration: none;

}
.footer-menu li a:hover {
  color: #fff;
}
.copyright-sec {
  display: block;
  position: relative;
  margin-top: 60px;
  padding-top: 20px;
  border-top: 1px solid #565656;
}
.copyrights-content {
  font-size: 14px;
  color: rgb(177, 177, 177);
}
.copyrights-content a {
  color: #3fb2cd;
  text-decoration: none;
}
/* ./ Footer Style */

/* Responsive Style */
@media screen and (max-width: 1300px){
    .about-img{
        width: 500px;
        max-width: 500px;
    }
    .abt-gif{
        width: 480px;
        height: 322px;
    }
    .abt-robot-img{
        width: 150px;
    }
}
@media screen and (max-width: 1199.98px){
    .heading-text-light{
        font-size: 100px;
    }
    .main-heading {
        font-size: 35px;
    }
    .hero-title {
        font-size: 50px;
    }
    .hero-desc {
        font-size: 16px;
    }
    .sts-num {
        font-size: 30px;
    }
    .sts-icon{
        width: 100px;
    }
    .feature-box{
        padding: 35px 30px;
    }
    .icon-img{
        width: 60px;
        margin-bottom: 25px;
    }
    .about-img{
        width: 400px;
        max-width: 400px;
    }
    .abt-gif{
        width: 380px;
        height: 255px;
    }
    .cta-box-wrap .cta-title {
        font-size: 40px;
    }
    .cta-action .btn {
        font-size: 16px;
        padding: 10px 30px;
    }
    .footer-logo a{
        font-size: 16px;
    }
}
@media screen and (max-width: 991.98px){
    .btn-primary{
        font-size: 16px;
    }
    .navbar-collapse {
        background-color: rgb(13, 13, 21);
        box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
        margin-top: 10px;
        padding: 15px;
    }
    .site-header ul.navbar-nav > li .nav-link {
        padding: 0;
    }
    .site-header .navbar .btn{
        padding: 5px 15px;
    }
    .hero-title {
        font-size: 40px;
    }
    .above-back-mockup .mockup-img{
        width: 300px;
    }
    .feature-box {
        padding: 20px;
    }
    .icon-img {
        width: 50px;
        margin-bottom: 20px;
    }
    .icon-box-title {
        font-size: 18px;
    }
    .heading-text-light {
        font-size: 80px;
    }
    .about-img{
        margin: 0 auto;
    }
    .cta-section {
        padding-top: 60px;
    }
    .footer-logo a {
        font-size: 20px;
    }
    .feature-box.fb-3, .feature-box.fb-2{
        top: 0;
    }
    .feature-steps [class*=col-]:nth-child(even) .feature-box {
        top: -70px;
    }
    .feature-steps:before{
        top: -150px;
        left: 20px;
        width: 120px;
        height: 130px;
    }
    .cta-box-wrap .cta-title {
        font-size: 35px;
    }
    .copyright-sec{
        margin-top: 30px;
    }
    .site-header .navbar.affix .navbar-collapse {
        box-shadow: none;
        padding: 15px 5px 5px;
    }
}
@media screen and (max-width: 767.98px){
    .above-back-mockup {
        top: unset;
        bottom: 0px;
        left: 0;
        right: 0;
        width: 100%;
    }
    .above-back-mockup .mockup-img{
        margin: 0 auto;
    }
    .hero-content {
        padding: 110px 0;
    }
    .heading-text-light {
        font-size: 60px;
    }
    .cta-box-wrap .cta-icon {
        font-size: 40px;
    }
    .cta-box-wrap .cta-title {
        font-size: 30px;
    }
    .cta-action .btn {
        font-size: 14px;
        padding: 10px 20px;
    }
    .feature-box p {
        font-size: 14px;
    }
    .main-heading {
        font-size: 28px;
        margin-bottom: 25px;
    }
}
@media screen and (max-width: 575.98px){
    .feature-steps [class*=col-]:nth-child(even) .feature-box {
        top: 0;
    }
    .above-back-mockup .mockup-img {
        width: 280px;
    }
    .hero-title {
        font-size: 36px;
    }
    .heading-text-light {
        font-size: 50px;
    }
    .feature-section{
        padding-bottom: 60px;
    }
    .about-img {
        width: 350px;
        max-width: 350px;
    }
    .abt-gif {
        width: 330px;
        height: 220px;
    }
    .abt-robot-img {
        width: 120px;
    }
    .sts-title{
        font-size: 16px;
    }
    .sts-icon {
        width: 80px;
    }
    .sts-num {
        font-size: 26px;
    }
}
@media screen and (max-width: 350px){
    .navbar-brand {
        font-size: 16px;
    }
    .site-header .navbar .navbar-toggler{
        width: 38px;
        height: 38px;
    }
    .site-header .navbar .navbar-toggler .navbar-toggler-icon{
        margin-left: 9px;
    }
}
/* ./ Responsive Style */

/* For Signup Template Page the custom emoji buttons
They would otherwise be big as fuck */
.custom-button-image {
  width: 16px !important;
}

/* For whatever reason this also needs to be at the top level.
This is needed because otherwise `field titles` would have a big margin bottom to
the `field values`. */
.embed-field-name p {
  margin: 0px !important;
}

/* Same. Also needs to be here to format the breakline white */
.embed-field-value hr {
  /* color it white */
  border-color: white;
}


/* Make dropdown in template builder look nicer. Needs to be here because in component css it does not work */
.emoji-type-select.mat-mdc-select-panel {
  min-width: 250px;
}

/* This is needed for the up/down count icons in the event date/time picker */
@import '~material-design-icons/iconfont/material-icons.css';

/* Needed here to ensure that the embed preview markdown bold is actually bold. */
strong {
  font-weight: bold;
}

.btn-outline-primary.btn-login-custom {
  border-color: #72C6DA !important;
  color: #72C6DA;
}

.btn-outline-primary.btn-login-custom:hover {
  background-color: #72C6DA; /* Change background on hover */
  color: #292E34; /* Change text color on hover */
}

.btn-light.btn-invite-custom {
  color: #3fb2cd;
}

/* Needed to center the text in the toggle button on the signup-template component */
.toggle-label .mdc-form-field {
  display: flex;
  align-items: center;
}

.toggle-label .mdc-label {
  margin-bottom: 0;
}
